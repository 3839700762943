import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const PushAndPull = () => (
  <Layout
    title="Push and Pull - Science at Home"
    className="science-starter has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Push and Pull</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Push and Pull</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../bernoulli-balance" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="98e0154b-c49c-4bb1-aea9-2b546cbeede0"
                className="de-vid"
              ></Video>
              <Link to="../diffusion-with-miss-america-2020" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Follow along with 3M’s Sam Reiss, as he shows you that magnetism
              is more than just a simple push and pull – it’s an example of the
              power of the earth itself.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              While magnetism has been around forever, people only somewhat
              recently figured out how to harness its power. In the 16th
              century, Queen Elizabeth tasked her personal scientist William
              Gilbert with figuring out why her explorers compasses were failing
              in certain areas of the world. They knew that the problem was
              related to magnetism, but were unsure as to how they could solve
              the problem. It took significant experimentation by Gilbert to
              figure that this was due to the influence of Earth’s magnetism. He
              had to try all sorts of materials to see which were affected by
              magnets and which were not. Let’s recreate some of that discovery
              process here today.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Magnetism</li>
              <li>Forces</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>At least one magnet</li>
              <li>10-15 small objects from your home</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Collect 10-15 items from around your home. Try to pick some
                objects that you think will attract to a magnet, some objects
                that will not, and some you aren’t sure. Make sure you don’t
                grab anything electronic. Some things that might be interesting
                to try are keys, aluminum foil, coins, buttons, wooden blocks,
                paper clips, stuffed animals, pencils, books, silverware,
                plastic toys, paper, and socks.
              </li>
              <li>
                Make a prediction: What objects will and will not attract to a
                magnet? Make one pile for things you think will attract, one
                pile for things that might not attract, and one pile for things
                you aren’t sure.
              </li>
              <li>
                Choose an object to test. Bring the magnet close to the object
                and see if you can feel it attract or repel.
              </li>
              <li>
                Sort the objects into two piles: things that do attract or
                repel, and things that don’t. If you have an object where part
                of it attracts to a magnet and part of it doesn’t make a
                decision on where it goes, or make a new pile of objects like
                that.
              </li>
              <li>
                Make some observations about similarities and differences in the
                two (or three) piles. What kinds of things attract to magnets?
              </li>
              <li>
                Remember to clean up when you are done. Put all of the objects
                that you used back where you found them.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              The only objects that attract to your magnet will have some iron,
              steel (which is mostly made of iron), cobalt, or nickel in them.
              There might have been some things that were made of metal that
              didn’t attract to your magnet. That is because they don’t contain
              iron, cobalt or nickel. Other metals like copper, tin, and
              aluminum don’t attract to a magnet. Things like wood, cloth, and
              plastic also do not attract to magnets. This is because of the way
              the atoms in different materials behave. Everything in the world
              contains magnetic domains, which determines whether or not
              something will attract to a magnet. Think of a domain as a mini
              piece of a magnet inside an object. Every object has lots of
              domains, and in most things the domains are messy and
              disorganized, so they cancel each other out, so they don’t act
              like a magnet. When things are magnetic, the domains are lined up
              with each other, so they can make a magnetic field. Some special
              materials like iron, nickel, and cobalt have domains that can
              switch back and forth from being lined up with a magnetic field,
              to messy and disorganized so they stop acting like a magnet. This
              is why only iron, nickel, and cobalt attract to magnets.
            </p>
            <p>
              Now that you’ve tested objects around the house, if you can get
              outside, see if you can find objects out there to try this. In
              particular, if you have access to a yard or a park, that can be a
              great place to find things in nature to try. How many of the
              things you find in nature do you think will work? If they do
              attract or repel, what does that tell you about the object?
            </p>
            <p>
              A fun thing to do now that you know all this might be to have a
              magnet with you and check things whenever you think of it. If you
              come across something you’ve never tested with a magnet before,
              get out your magnet and see if it attracts or repels or does
              nothing. Again, try to remember what that tells you about the
              object you tested. Also, as before, avoid testing objects with
              electronics since they can sometimes break if they’re too close to
              magnets. And while they aren’t so common anymore, if you come
              across a cassette or video tape, keep the magnet away from those
              as well since those will erase with a magnet.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS1.A: Structure and Properties of Matter
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>2-PS1-1.</em> Different kinds of matter exist and
                        can be described by its observable properties.
                      </li>
                      <li>
                        <em>2-PS1-2.</em> Different properties are suited to
                        different purposes.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>5-PS1-1.</em> Matter of any type can be subdivided
                        into particles that are too small to see, but event then
                        the matter still exists and can be detected by other
                        means.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS1-1.</em> Atoms form molecules that range in
                        size from two to thousands of atoms. Molecules may be
                        extended structures with repeating subunits.
                      </li>
                      <li>
                        <em>MS-PS1-2.</em> Each pure substance has
                        characteristic physical and chemical properties that can
                        be used to identify it.
                      </li>
                      <li>
                        <em>MS-PS1-4.</em> In a solid, atoms are closely spaced
                        and may vibrate in position but do not change relative
                        locations.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS1-1.</em> Each atom has a charged substructure
                        consisting of a nucleus, which is made of protons and
                        neutrons, surrounded by electrons.
                      </li>
                      <li>
                        <em>HS-PS1-2.</em> The periodic table orders elements
                        horizontally by the number of protons in the atomâ€™s
                        nucleus and places those with similar chemical
                        properties in columns. The repeating patterns of this
                        table reflect pattern of outer electron states.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> The structure and interactions of
                        matter at the bulk scale are determined by electrical
                        forces within and between atoms.
                      </li>
                      <li>
                        <em>HS-PS1-4.</em> Stable forms of matter are those in
                        which the electric and magnetic field energy is
                        minimized.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS2.A: Forces and Motion
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> Pushes and pulls can have different
                        strengths and directions.
                      </li>
                      <li>
                        <em>K-PS2-2.</em> Pushing or pulling on an object can
                        change the speed or direction of its motion and can
                        start or stop it.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Each force acts on one particular
                        object and has both strength and a direction. An object
                        at rest typically has multiple forces acting on it, but
                        they add to give zero net force on the object. Forces
                        that do not sum to zero can cause changes in the
                        objectâ€™s speed or direction of motion.
                      </li>
                      <li>
                        <em>3-PS2-2.</em> The patterns of an object's motion in
                        various situations can be observed and measured; when
                        that past motion exhibits a regular pattern, future
                        motion can be predicted from it.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-1.</em> For any pair of interacting objects,
                        the force exerted by the first object on the second
                        object is equal in strength to the force that the second
                        object exerts on the first, but in the opposite
                        direction.
                      </li>
                      <li>
                        <em>MS-PS2-2.</em> The motion of an object is determined
                        by the sum of the forces acting on it; if the total
                        force on the object is not zero its motion will change.
                        The greater the mass of the object, the greater the
                        force needed to achieve the same change in motion. For
                        any given object, a larger force causes a larger change
                        in motion.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-1.</em> Newton's second law accurately
                        predicts changes in the motion of macroscopic objects.
                      </li>
                      <li>
                        <em>HS-PS2-2.</em> Momentum is defined for a particular
                        frame of reference; it is the ass times the velocity of
                        the object. In any system, total momentum is always
                        conserved.
                      </li>
                      <li>
                        <em>HS-PS1-3.</em> If a system interacts with objects
                        outside itself, the total momentum of the system can
                        change; however, any such change is balanced by changes
                        in the moment of objects outside the system.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS2.B: Types of Interactions
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> When objects touch or collide, they
                        push on one another and can change motion.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>3-PS2-1.</em> Objects in contact exert forces on
                        each other.
                      </li>
                      <li>
                        <em>3-PS2-3.</em> Electric and magnetic forces between a
                        pair of objects do not require the objects to be in
                        contact.
                      </li>
                      <li>
                        <em>3-PS2-4.</em> The sizes of the forces in each
                        situation depend on the properties of the objects and
                        their distance apart and, for forces between two
                        magnets, on their orientation relative to each other.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS2-3.</em> Electric and magnetic forces can be
                        attractive or repulsive, and their sizes depend on the
                        magnitudes of the charges, currents, or magnetic
                        strengths involved and on the distance between the
                        interacting objects.
                      </li>
                      <li>
                        <em>MS-PS2-5.</em> Forces that act at a distance can be
                        explained by fields that extend through space and can be
                        mapped by their effect on a test object.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS2-4.</em> Forces at a distance are explained by
                        fields permeating space than can transfer energy through
                        space.
                      </li>
                      <li>
                        <em>HS-PS2-5.</em> Magnets or electric currents cause
                        magnetic field; electric charges or changing magnetic
                        fields cause electric fields.
                      </li>
                      <li>
                        <em>HS-PS2-6.</em> Attraction and repulsion between
                        electric charges at the atomic scale explain the
                        structure, properties, and the constant forces between
                        material objects.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    PS3.C: Relationship Between Energy and Forces
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>K-PS2-1.</em> A bigger push or pull makes things go
                        faster.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-3.</em> When objects collide, the contact
                        forces transfer energy so as to change the objects'
                        motions.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS3-2.</em> When two objects interact, each one
                        exerts a force on the other that can cause energy to be
                        transferred to or from the object.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-5.</em> When two objects interacting through
                        a field change relative position, the energy stored in
                        the field is changes.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="PushAndPull-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Sam Reiss</h3>
                <h4>Still need...</h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="BernoulliBalance-Thumbnail.png" />
              <div className="card__content">
                <h3>Bernoulli Balance</h3>
                <h4>
                  A jetliner can weigh over 300 tons, so why doesn’t it just
                  drop out of the air?
                </h4>
                <p>
                  3M’s SVP of Corporate Affairs, Denise Rutherford explains the
                  Bernoulli principle and how without it, planes (and birds)
                  couldn’t fly.
                </p>
              </div>
              <Link
                to="../bernoulli-balance"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="DiffusionWithMiss-Thumbnail.png" />
              <div className="card__content">
                <h3>Diffusion with Miss America 2020</h3>
                <h4>
                  Ever wonder why things mix (or don’t mix) differently in
                  different temperatures of water?
                </h4>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she explains diffusion and how substances move though
                  water.
                </p>
              </div>
              <Link
                to="../diffusion-with-miss-america-2020"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          {/* Feeling Sound */}
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="FeelingSound-Thumbnail.png" />
              <div className="card__content">
                <h3>Feeling Sound</h3>
                <p>
                  Believe it or not, you can feel sound! Join Gitanjali Rao,
                  former Discovery Education 3M Young Scientist Challenge
                  winner, as she teaches about the frequency of sound and how we
                  perceive pitch.
                </p>
              </div>
              <Link to="../feeling-sound" className="button bg-gradient-purple">
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default PushAndPull;
